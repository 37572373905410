import CryptoJS from "crypto-js";
import { QueueServiceClient } from '@azure/storage-queue';
import { TableClient, AzureSASCredential } from "@azure/data-tables";
import { BlobServiceClient } from '@azure/storage-blob'
import type { FullOperationResponse } from "@azure/core-client/dist/commonjs/interfaces";
import { azureAccountName, azureTableName, timeoutDelay } from "../constants";

export interface DocumentContentRequest {
  Body: string;
  partitionKey: string;
  rowKey: string;
  Response?: string;
  [key: string]: any;
}

const azureBlobUrl = `https://${azureAccountName}.blob.core.windows.net`;
const azureTableUrl = `https://${azureAccountName}.table.core.windows.net`;
const azureQueueUrl = `https://${azureAccountName}.queue.core.windows.net`;

export const addMessageToQueue = async (queueName: string, message: any, queueSasToken: string) => {
  const queueServiceClient = new QueueServiceClient(`${azureQueueUrl}?${queueSasToken}`);
  const queueClient = queueServiceClient.getQueueClient(queueName);

  try {
    const base64Message = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(message));

    await queueClient.sendMessage(base64Message);
  } catch (error) {
    console.error("Error adding message to queue:", error.message);
  }
};

export const addEntityToTable = async (entity: DocumentContentRequest, sasToken: string, recursiveDepthCounter: number = 0) => {
  try {
    const tableClient = new TableClient(azureTableUrl, azureTableName, new AzureSASCredential(sasToken));
    const onResponse = (res: FullOperationResponse) => {
      const statusGroup = String(res.status).charAt(0);

      if ((statusGroup === "4" || statusGroup === "5") && recursiveDepthCounter < 5) {
        setTimeout(() => addEntityToTable(entity, sasToken, recursiveDepthCounter + 1), timeoutDelay);
      }
    }

    await tableClient.createEntity(entity, { onResponse });
  } catch (error) {
    console.error("Error adding entity to table:", error.message);
  }
};

export const getEntityFromTable = async (partitionKey: string, rowKey: string, sasToken: string) => {
  try {
    const tableClient = new TableClient(azureTableUrl, azureTableName, new AzureSASCredential(sasToken));
    
    return await tableClient.getEntity<DocumentContentRequest>(partitionKey, rowKey);
  } catch (error) {
    console.error("Error retrieving entity from table:", error.message);
  }
};

export const deleteEntityFromTable = async (partitionKey: string, rowKey: string, sasToken: string) => {
  const tableClient = new TableClient(azureTableUrl, azureTableName, new AzureSASCredential(sasToken));

  tableClient.deleteEntity(partitionKey, rowKey)
}

async function blobToString(blob: Blob): Promise<string> {
  return blob.text();
}

export const downloadBlob = async (containerName: string, blobName: string, sasToken: string) => {
  try {
    const blobServiceClient = new BlobServiceClient(`${azureBlobUrl}?${sasToken}`);
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(blobName);
    const downloadBlockBlobResponse = await blobClient.download();
    const blob = await downloadBlockBlobResponse.blobBody;
    const downloadedBlob = await blobToString(blob);

    containerClient.deleteBlob(blobName);

    return downloadedBlob;
  } catch (error) {
    console.error("Error downloading blob:", error.message);
  }
};

export const deleteBlob = async (containerName: string, blobName: string, sasToken: string) => {
  try {
    const blobServiceClient = new BlobServiceClient(`${azureBlobUrl}?${sasToken}`);
    const containerClient = blobServiceClient.getContainerClient(containerName);

    return containerClient.deleteBlob(blobName);
  } catch (error) {
    console.error("Error downloading blob: ", error.message);
  }
}


export const uploadDataToBlob = async (containerName: string, blobName: string, data: any, sasToken: string, recursiveDepthCounter: number = 0) => {
  try {
    const blobServiceClient = new BlobServiceClient(`${azureBlobUrl}?${sasToken}`);
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    const dataString = JSON.stringify(data); 
    const blob = new Blob([dataString], { type: 'application/json;charset=utf-8' });

    const uploadBlobResponse = await blockBlobClient.uploadData(blob);
    const statusGroup = String(uploadBlobResponse._response.status).charAt(0);

    if ((statusGroup === "4" || statusGroup === "5") && recursiveDepthCounter < 5) {
      setTimeout(() => uploadDataToBlob(containerName, blobName, data, sasToken, recursiveDepthCounter + 1), timeoutDelay);
    }

    console.log("Blob was uploaded successfully. requestId: ", uploadBlobResponse.requestId);

    return uploadBlobResponse.requestId;
  } catch (error) {
    console.error("Error uploading blob:", error.message);

    throw error;
  }
};

export const uploadFileToBlob = async (containerName: string, blobName: string, file: any, sasToken: string): Promise<void> => {
  try {
    const blobServiceClient = new BlobServiceClient(`${azureBlobUrl}?${sasToken}`);
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    await blockBlobClient.uploadData(file);
  } catch (error) {
    console.error("Error uploading blob:", error.message);

    throw error;
  }
}
import React, {useCallback, useEffect, useRef, useState} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { ToolbarAccordion, TextAnnotator, LabelSelector, AnnotationList, Preloader } from '../components';
import {
  type RootState,
  setAnnotations,
  updateAnnotation,
  setDocumentContent,
  setDocumentSourceType
} from '../store';
import { addEntityToTable, addMessageToQueue, deleteEntityFromTable, downloadBlob, getEntityFromTable, uploadDataToBlob } from '../api/queueApi';
import {getAccountInfo, getBlobNameAndSasTokenFromURL, useAzureGarbageCollector, waitFor} from '../utils';
import { msalInstance } from '../index';
import type { Category, Label } from '../types';
import { setQuestionLoadError } from '../store/slices/annotatorSlice';
import { getSasTokenUriForBlob, getSasTokenUriForQueue, getSasTokenUriForTableEntity} from "../api";
import { azureContainerName, azureFunctionQueueCode, azureFunctionBlobCode, azureFunctionTableCode, azureTableName, maxAttempts, timeoutDelay } from "../constants";

const categories = [
  // { labelName: 'Edit', color: '#3357FF', categoryId: 'edit' },
  // { labelName: 'Skip', color: '#FF33A1', categoryId: 'skip' },
  // { labelName: 'Update', color: '#33FFF5', categoryId: 'update' },
  // { labelName: 'Rewrite', color: '#33FF57', categoryId: 'rewrite' },
  // { labelName: 'Question', color: '#FFD133', categoryId: 'question' }, 
  { labelName: 'Added Question', color: '#ECEE63', categoryId: 'userQuestion' }, 
  // { labelName: 'Redundant', color: '#FF5733', categoryId: 'redundant' }, 
];

const questionColor = '#FFD133';

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '85vh',
  margin: '0',
  overflow: 'hidden',
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

const AnswersBox = styled(Box)(({ theme }) => ({
  top: 0,
  zIndex: 5,
  width: '100%',
  position: 'sticky',
  padding: '5px 0', 
  background: '#FFFFFF'
}));

const AnswersButton = styled(Button)(({theme}) => ({
  color: '#fff',
  width: '100%',
  background: '#02023f',
  '&:hover': {
    background: '#2b2b75',
  }
}));

const TextContainer = styled(Container)(({ theme }) => ({
  width: '65%',
  margin: '10px 0 0',
  overflowY: 'auto',
  height: '100%',
  paddingRight: '10px',
  paddingBottom: '20px',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
}));

const SidebarContainer = styled(Container)(({ theme }) => ({
  maxWidth: '35%',
  background: '#eeeeee',
  padding: '0 3px 10px',
  width: '35%',
  overflowY: 'auto',
  height: '100%',
  [theme.breakpoints.up('lg')]: {
    maxWidth: '35%',
  },
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
}));

const StickyToolbarAccordion = styled(ToolbarAccordion)({
  position: 'sticky',
  top: 0,
  zIndex: 1,
  backgroundColor: '#eeeeee',
});

const StickyDocName = styled(Box)({
  position: 'sticky',
  top: 0,
  width: '100%',
  background: '#FFFFFF',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingLeft: '20px',
  boxSizing: 'border-box',
  zIndex: 5
});

const DocName = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 'bold'
})

type AnswersContent = {
  Id: string;
  AnswersSharepoint: string[];
  AnswersAnswersWebsite: string[];
}

type FoundAnnotation = {
  Context: string;
  StartIndex: number;
  EndIndex: number;
  Question: string;
}


const Annotator: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { addRequestData, deleteTableRequest } = useAzureGarbageCollector();

  const annotatorContent = useSelector((state: RootState) => state.document.documentContent);
  const loadFrom = useSelector((state: RootState) => state.annotator.loadFrom);
  const annotations = useSelector((state: RootState) => state.annotator.annotations);
  const docType = useSelector((state: RootState) => state.document.documentSourceType);
  const questionLoadError = useSelector((state: RootState)  => state.annotator.questionLoadError);

  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [loadingAnswers, setLoadingAnswers] = useState([]);
  const mounted = useRef(false);
  const processingRequests = useRef([]);

  const FileName = searchParams.get('FileName');
  const SiteId = searchParams.get('SiteId');
  const DriveId = searchParams.get('DriveId');
  const ItemId = searchParams.get('ItemId');
  const BlobName = searchParams.get('BlobName');
  const DocType = searchParams.get('DocType');

  useEffect(() => {
    dispatch(setQuestionLoadError(false));
    mounted.current = true;

    if (DocType !== "undefined") {
      dispatch(setDocumentSourceType(DocType));
    }

    if (loadFrom !== 'store') {
      getAnnotatorContents();
    }

    return () => {
      mounted.current = false;
    }
  }, []);


  const removeStyles = (htmlString: string) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    
    const styleElements = doc.getElementsByTagName('style');
    for (let i = styleElements.length - 1; i >= 0; i--) {
      styleElements[i].parentNode.removeChild(styleElements[i]);
    }
  
    const elements = doc.getElementsByTagName('*');
    for (let i = 0; i < elements.length; i++) {
      elements[i].removeAttribute('style');
    }
  
    return doc.body.innerHTML;
  }

  const scrollToAnnotation = (label: Label) => {
    const element = document.getElementById(label.labelId);

    element.scrollIntoView({
      behavior: 'smooth',
    });
  }

  async function getDocumentContent(searchItem: any, maxAttempts: number, containerName: string, sasToken: string) {
    let attempt = 0;
  
    while (attempt < maxAttempts && mounted.current) {
      try {
        const docContent = await getEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);
        if (docContent && docContent.ResponseErrorMessage) {
          console.log(docContent.ResponseStatusCode, docContent.ResponseErrorMessage)
          break;
        }

        if (docContent && docContent.Response) {
          const { blobName, sasToken: blobSasToken } = getBlobNameAndSasTokenFromURL(docContent.Response);
          const documentContent = await downloadBlob(containerName, blobName, blobSasToken);
          
          const transformedContent = removeStyles(documentContent);
          const formattedContent = transformedContent.replace(/●[\r\n]+/g, '');

          dispatch(setDocumentContent(formattedContent));
          deleteEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);
          deleteTableRequest(searchItem.rowKey);
          break;
        }
      } catch (error) {
        console.error('Error retrieving entity from table:', error);
      }
      attempt++;
      await new Promise(resolve => setTimeout(resolve, timeoutDelay));
    }
  
    if (attempt === maxAttempts) {
      console.error('Maximum retries reached, no Response found');
    }
  }

  const setAnswers = (answers: AnswersContent[]) => {
    if (!answers.length) return; 

    const answersItem = answers[0];

    const annotationToUpdate = annotations.find(item => item.labelId === answersItem.Id)
    
    const answersWebsiteAnswers = answersItem.AnswersAnswersWebsite?.map(answer => {
      return {
        answer: answer,
        selected: false,
        badge: "AnswersWeb"
      }
    });
    const sharePointAnswers = answersItem.AnswersSharepoint.map(answer => {
      return {
        answer: answer,
        selected: false,
        badge: "SharePoint"
      }
    });
  
    const newAnnotation = {
      ...annotationToUpdate,
      answers: [...answersWebsiteAnswers, ...sharePointAnswers]
    } 

    dispatch(updateAnnotation(newAnnotation))
  }

   const getAnswersContent = async (searchItem: any, maxAttempts: number, annotation: any, loadingAnswersIds: string[], sasToken: string) => {
    let attempt = 0;
    let isProcessed = false;

    do {
      const tableItem = await getEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);

      if (tableItem && tableItem.ResponseStatusCode === 202) {
        isProcessed = true;
      }
    } while (!isProcessed)

    while (attempt < maxAttempts && mounted.current) {
      try {
        const docContent = await getEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);

        if (docContent && docContent.ResponseErrorMessage) {
          console.log(docContent.ResponseStatusCode, docContent.ResponseErrorMessage)
          if (docContent.ResponseStatusCode === 429) {
            deleteEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);
            deleteTableRequest(searchItem.rowKey);

            const annotationForAnswer = annotations.find(item => item.labelId === annotation.Id);

            setTimeout(() => getAnswers(annotationForAnswer), timeoutDelay)
          }
          break;
        }

        if (docContent && docContent.Response) {
          const answersContent = JSON.parse(docContent.Response);
          const idx = loadingAnswersIds.findIndex(id => id === annotation.Id);

          loadingAnswersIds.splice(idx, 1);
          processingRequests.current = processingRequests.current.filter(id => id !== docContent.rowKey);

          setAnswers(answersContent);
          setLoadingAnswers([...loadingAnswersIds]);
          deleteEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);
          deleteTableRequest(searchItem.rowKey);
          break;
        }
      } catch (error) {
        console.error('Error retrieving entity from table:', error);
      }
      attempt++;
      await new Promise(resolve => setTimeout(resolve, timeoutDelay));
    }

    if (attempt === maxAttempts) {
      const idx = loadingAnswersIds.findIndex(id => id === annotation.Id);

      loadingAnswersIds.splice(idx, 1);
      processingRequests.current = processingRequests.current.filter(id => id !== searchItem.rowKey);

      const emptyAnswer: AnswersContent[] = [{
        AnswersAnswersWebsite: [],
        AnswersSharepoint: [], 
        Id: annotation.Id 
      }]
        
      setAnswers(emptyAnswer);
      setLoadingAnswers([...loadingAnswersIds]);
      deleteEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);
      deleteTableRequest(searchItem.rowKey);
      console.error('Maximum retries reached, no Response found');
    }
  }

  const setNewAnnotations = (parsedQuestion: FoundAnnotation[], annotation: Label) => {
    const filteredAnnotations = annotations.filter(item => annotation.labelId !== item.labelId);
    if (!parsedQuestion.length) {
      const annotationWithoutQuestion = {
        labelId: uuidv4(),
        label: "Added Question",
        start: annotation.start,
        end: annotation.end,
        color: '#ECEE63',
        text: annotation.text,
        question: "No question found"
      }

      const updatedAnnotations = [...filteredAnnotations, annotationWithoutQuestion];

      dispatch(setAnnotations(updatedAnnotations))
    } else {
      const newAnnotations = parsedQuestion.map(item => {
        return {
          labelId: uuidv4(),
          label: "Added Question",
          start: item.StartIndex,
          end: item.EndIndex,
          color: '#ECEE63',
          text: item.Context,
          question: item.Question
        }
      });

      const updatedAnnotations = [...filteredAnnotations, ...newAnnotations];

      dispatch(setAnnotations(updatedAnnotations))
    }
  }

  async function getQuestionFromTextContent(searchItem: any, maxAttempts: number, containerName: string, annotation: Label, sasToken: string) {
    let attempt = 0;
  
    while (attempt < maxAttempts && mounted.current) {
      try {
        const docContent = await getEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);
        if (docContent && docContent.ResponseErrorMessage) {
          console.log(docContent.ResponseStatusCode, docContent.ResponseErrorMessage)
          break;
        }

        if (docContent && docContent.Response) {
          const { blobName, sasToken: blobSasToken } = getBlobNameAndSasTokenFromURL(docContent.Response);
          const question = await downloadBlob(containerName, blobName, blobSasToken);
          const parsedQuestion: FoundAnnotation[] = JSON.parse(question);
                   
          setNewAnnotations(parsedQuestion, annotation);

          deleteEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);
          deleteTableRequest(searchItem.rowKey);
          break;
        }
      } catch (error) {
        console.error('Error retrieving entity from table:', error);
      }
      attempt++;
      await new Promise(resolve => setTimeout(resolve, timeoutDelay));
    }
  
    if (attempt === maxAttempts) {
      console.error('Maximum retries reached, no Response found');
    }
  }

  async function getQuestions(searchItem: any, maxAttempts: number, containerName: string, sasToken: string) {
    let attempt = 0;
  
    while (attempt < maxAttempts && mounted.current) {
      try {
        const questions = await getEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);

        if (questions && questions.ResponseErrorMessage) {
          console.log(questions.ResponseStatusCode, questions.ResponseErrorMessage)
          dispatch(setQuestionLoadError(true));
          break;
        }

        if (questions && questions.Response) {
          const { blobName, sasToken: blobSasToken } = getBlobNameAndSasTokenFromURL(questions.Response);
          const questionsList = await downloadBlob(containerName, blobName, blobSasToken);

          deleteEntityFromTable(searchItem.partitionKey, searchItem.rowKey, sasToken);
          deleteTableRequest(searchItem.rowKey);

          // @ts-ignore
          const transformedQuestionList = JSON.parse(questionsList).map(item => {
            return {
              question: item.Question,
              start: item.StartIndex * 1.9 + 'Question'.length,
              end: item.EndIndex * 1.9 + 'Question'.length,
              labelId: `Question-${uuidv4()}`,
              label: 'Question', 
              color: questionColor,
              startIndex: item.StartIndex,
              endIndex: item.EndIndex,
              text: item.Context
            }
          });

          dispatch(setAnnotations(transformedQuestionList));
          break;
        }
      } catch (error) {
        console.error('Error retrieving entity from table:', error);
      }
      attempt++;
      await new Promise(resolve => setTimeout(resolve, timeoutDelay));
    }
  
    if (attempt === maxAttempts) {
      console.error('Maximum retries reached, no Response found questions');
      dispatch(setQuestionLoadError(true));
    }
  }

  const getAnnotatorContents = async () => {
    const account = await getAccountInfo(msalInstance.getActiveAccount());
    const contentId = uuidv4();
    const questionId = uuidv4();
    const questionQueue = 'get-questions-from-document';
    const contentQueue = 'get-document-content-with-basic-formatting';
    const body = docType === "AzureBlobStorage" ? {
      FileName,
      BlobName,
      DocumentSourceType: docType
    } : {
      FileName,
      SiteId,
      DriveId,
      AccessToken: account.accessToken,
      ItemId,
    }

    const contentTableItem = {
      partitionKey: contentQueue,
      rowKey: contentId,
      Body: JSON.stringify(body),
    }
    const questionTableItem = {
      partitionKey: questionQueue,
      rowKey: questionId,
      Body: JSON.stringify(body),
    }
    const contentTableSasToken = await getSasTokenUriForTableEntity(azureTableName, azureFunctionTableCode, contentId, contentQueue, account.idToken);
    const questionTableSasToken = await getSasTokenUriForTableEntity(azureTableName, azureFunctionTableCode, questionId, questionQueue, account.idToken);

    await addEntityToTable(contentTableItem, contentTableSasToken);
    await addEntityToTable(questionTableItem, questionTableSasToken);
    addRequestData('table', { rowKey: contentId, partitionKey: contentQueue, sasToken: contentTableSasToken });
    addRequestData('table', { rowKey: questionId, partitionKey: questionQueue, sasToken: questionTableSasToken });

    const contentMessage = {
      Id: ItemId,
      TablePartitionKey: contentQueue,
      TableRowKey: contentId,
    }
    const questionMessage = {
      Id: ItemId,
      TablePartitionKey: questionQueue,
      TableRowKey: questionId,
    }

    const contentMessageSasToken = await getSasTokenUriForQueue(azureFunctionQueueCode, contentQueue, account.idToken);
    const questionMessageSasToken = await getSasTokenUriForQueue(azureFunctionQueueCode, questionQueue, account.idToken);

    await addMessageToQueue(contentQueue, JSON.stringify(contentMessage), contentMessageSasToken);
    await getDocumentContent(contentTableItem, maxAttempts, azureContainerName, contentTableSasToken);
    await addMessageToQueue(questionQueue, JSON.stringify(questionMessage), questionMessageSasToken);
    await getQuestions(questionTableItem, maxAttempts, azureContainerName, questionTableSasToken)
  }

  const getAnswers = useCallback(async (question?: Label) => {
    const account = await getAccountInfo(msalInstance.getActiveAccount());
    const queueName = 'get-answers-from-questions';
    let questionParam;

    if (question) {
      questionParam = [{
        //@ts-ignore
        Id: question.labelId,
        Question: question.question,
        NumberOfAnswers: 3,
        PromptFromUser: '',
        AccessToken: account.accessToken
      }]
    } else {
      const questions = annotations.map(item => {
        return {
          Id: item.labelId,
          Question: item.question,
          NumberOfAnswers: 3,
          PromptFromUser: '',
          AccessToken: account.accessToken
        }
      })

      questionParam = questions;
    }

    const loadingAnswersIds = questionParam.map(item => item.Id);
    setLoadingAnswers(loadingAnswersIds.length > 1 ? loadingAnswersIds : [...loadingAnswers, ...loadingAnswersIds]);

    for (const item of questionParam) {
      const uuid = uuidv4();

      await waitFor(() => processingRequests.current.length <= 10);

      const blobSasToken = await getSasTokenUriForBlob(azureContainerName, uuid, azureFunctionBlobCode, account.idToken);
      uploadDataToBlob(azureContainerName, uuid, [item], blobSasToken);
      addRequestData('blob', { containerName: azureContainerName, blobName: uuid, sasToken: blobSasToken });

      const tableItem = {
        partitionKey: queueName,
        rowKey: uuid,
        Body: uuid,
      }
      const tableSasToken = await getSasTokenUriForTableEntity(azureTableName, azureFunctionTableCode, uuid, queueName, account.idToken);
      addEntityToTable(tableItem, tableSasToken);
      addRequestData('table', { partitionKey: queueName, rowKey: uuid, sasToken: tableSasToken });

      processingRequests.current.push(tableItem.rowKey);

      const message = {
        Id: item.Id,
        TablePartitionKey: queueName,
        TableRowKey: uuid,
      }
      const queueSasToken = await getSasTokenUriForQueue(azureFunctionQueueCode, queueName, account.idToken);
      addMessageToQueue(queueName, JSON.stringify(message), queueSasToken);

      getAnswersContent(tableItem, maxAttempts, item, loadingAnswersIds, tableSasToken);
    }
  }, [annotations, loadingAnswers]);

  const getQuestionFromText = async (text: string, annotation: Label) => {
    const account = await getAccountInfo(msalInstance.getActiveAccount());
    const uuid = uuidv4();
    const queueName = 'get-questions-from-text';

    const blobSasToken = await getSasTokenUriForBlob(azureContainerName, uuid, azureFunctionBlobCode, account.idToken);
    await uploadDataToBlob(azureContainerName, uuid, text, blobSasToken);
    addRequestData('blob', { containerName: azureContainerName, blobName: uuid, sasToken: blobSasToken });

    const contentItem = {
      partitionKey: queueName,
      rowKey: uuid,
      Body: uuid,
    }
    const tableSasToken = await getSasTokenUriForTableEntity(azureTableName, azureFunctionTableCode, uuid, queueName, account.idToken);
    await addEntityToTable(contentItem, tableSasToken);
    addRequestData('table', { partitionKey: queueName, rowKey: uuid, sasToken: tableSasToken });

    const message = {
      Id: ItemId,
      TablePartitionKey: queueName,
      TableRowKey: uuid,
  }
    const queueSasToken = await getSasTokenUriForQueue(azureFunctionQueueCode, queueName, account.idToken);
    await addMessageToQueue(queueName, JSON.stringify(message), queueSasToken);

    await getQuestionFromTextContent(contentItem, maxAttempts, azureContainerName, annotation, tableSasToken);
  }

  const updateQueryParams = () => {
    const params = new URLSearchParams();
    params.append('FileName', FileName);
    params.append('SiteId', SiteId);
    params.append('DriveId', DriveId);
    params.append('ItemId', ItemId);

    navigate({
      pathname: '/review-final',
      search: params.toString()
    });
  };

  const handleEdit = useCallback((label: Label) => {
    const newAnnotations = annotations.map(item => {
      if (item.labelId === label.labelId) {
        return label
      } else {
        return item
      }
    })

    dispatch(setAnnotations([...newAnnotations]));
  }, [annotations]);

  const handleUpdateLabels = (updatedLabels: Label[]) => {
    dispatch(setAnnotations(updatedLabels));
  };

  const handleDelete = useCallback((labelId: string) => {
    const updatedLabels = annotations.filter(annotation => annotation.labelId !== labelId);

    dispatch(setAnnotations(updatedLabels));
  }, [annotations]);

  const getQuestionsOnPress = () => {
    dispatch(setQuestionLoadError(false));
    getAnnotatorContents();
  }

  const everyQuestionHasAnswers = annotations.every(item => item.answers !== undefined);
  const noAnswersFound = annotations.every(item => item.answers?.length === 0);
  const allAnswersSelected = !noAnswersFound && annotations.every(item => item.answers?.every(answer => answer.selected === true));

  const selectAllAnswers = () => {
    if (noAnswersFound) return;

    const newAnnotations = annotations.map(item => {
      const newAnswers = item.answers.map(answer => {
        if (allAnswersSelected) {
          return { ...answer, selected: false }
        } else {
          return { ...answer, selected: true }
        }
      })
      return { ...item, answers: newAnswers }
    })
    dispatch(setAnnotations(newAnnotations));
  }
  const onReviewPage = () => {
    localStorage.setItem('annotations', JSON.stringify(annotations));
    updateQueryParams();
  }

  return (
    <StyledContainer disableGutters>
      <TextContainer>
        <StickyDocName>
          <DocName>{FileName}</DocName>
        </StickyDocName>
        <Container sx={{ paddingTop: '20px' }}>
          {
            annotatorContent.length ? 
            <TextAnnotator
              text={annotatorContent}
              categories={categories}
              labels={annotations}
              textDisplay="pre-wrap"
              selectedCategory={selectedCategory}
              getQuestionFromText={getQuestionFromText}
              onUpdateLabels={handleUpdateLabels}
              scrollToAnnotation={scrollToAnnotation}
            />
            :
            <Preloader classes={['partial']}/>
           }
        </Container>
      </TextContainer>
      <SidebarContainer disableGutters>
        {
          questionLoadError ? <AnswersButton onClick={getQuestionsOnPress}>Get Questions</AnswersButton> : ''
        }
        {annotations.length ? 
          <AnswersBox>
            <AnswersButton onClick={() => getAnswers()}>Get Answers</AnswersButton>
            {
              everyQuestionHasAnswers ? (
                <Box sx={{ marginTop: '5px', display: 'flex', flexDirection: 'row', background: '#02023f', borderRadius: '3px', justifyContent: 'space-around' }}>
                  <AnswersButton
                    onClick={onReviewPage}
                    sx={{
                      maxWidth: '50%',
                      borderRadius: 0,
                      borderRight: '1px solid #FFF',
                      width: '50%'
                    }}
                    >Results Overview</AnswersButton>
                  <FormGroup sx={{ color: '#FFF', maxWidth: '50%'}}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            color: '#FFF',
                            '&.Mui-checked': {
                              color: '#FFF',
                            },
                          }}
                          onChange={selectAllAnswers}
                          checked={allAnswersSelected}/>
                      }
                      sx={{
                        textTransform: 'uppercase',
                        '&.MuiFormControlLabel-root .MuiFormControlLabel-label': {
                          fontSize: '0.8rem',
                        }
                      }}
                      label="Select All Answers"
                      labelPlacement='start' />
                  </FormGroup>
                </Box>
              ) : ''
            }
          </AnswersBox>
          : ''
        }
        <StickyToolbarAccordion label="Add Labels">
          <LabelSelector categories={categories} onSelect={setSelectedCategory} />
        </StickyToolbarAccordion>
        <StickyToolbarAccordion label={`Labelled items: ${annotations.length ? annotations.length : ''}`}>
          {
            annotations.length ?
              <AnnotationList loadingAnswers={loadingAnswers} annotations={annotations} onEdit={handleEdit} onDelete={handleDelete} getAnswers={getAnswers} />
            :
              <Preloader />
          }
          { questionLoadError ? <Typography sx={{ textAlign: 'center' }}>An error has occurred try "Get Questions button"</Typography> : ''}
        </StickyToolbarAccordion>
      </SidebarContainer>
    </StyledContainer>
  );
};

export default Annotator;

// export const getFileDataFromUrl = (url: string) => {
//   return url.includes('http') ? url.substring(url.indexOf('%7B') + 3, url.indexOf('%7D')) : url;
// } 

export const getContrastColor = (hexColor: string) => {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 128 ? 'black' : 'white';
};

export const getFilesData = (data: any) => {
  const result = data?.value?.reduce((acc: number, curr: any) => {
    return acc ? acc : curr?.hitsContainers.reduce((itemAcc: number, item: any) => itemAcc || item?.hits.reduce((hitAcc: number, hitCurr: any) => {
      if (hitAcc) {
        return hitAcc;
      }
      if (hitCurr?.parentReference?.sharepointIds) {
        const {
          parentReference: {
            siteId, driveId, sharepointIds: { listItemUniqueId }, name,
          }
        } = hitCurr;
        return siteId && driveId && listItemUniqueId && name ? {
          siteId, driveId, listItemUniqueId, name
        } : null;
      }
      return null;
    }));
  });

  return result;
}

export const compareObjects = (first: Object, second: Object) => JSON.stringify(first) === JSON.stringify(second);

export const waitFor = (callback: () => boolean, interval: number = 500): Promise<void> => {
  return new Promise((resolve) => {
    const intervalId = setInterval(() => {
      if (callback()) {
        clearInterval(intervalId);
        resolve();
      }
    }, interval);
  });
}

export const getBlobNameAndSasTokenFromURL = (url: string) => {
  const [blobUrl, sasToken] = url.split('?');
  const parsedBlobUrl = blobUrl.split('/');
  const blobName = parsedBlobUrl[parsedBlobUrl.length - 1];

  return { blobName, sasToken };
}
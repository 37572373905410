import { useEffect, useState } from "react";
import { getAccountInfo } from "./MsGraphApiCall";
import { msalInstance } from "../index";
import { deleteBlob, deleteEntityFromTable } from "../api/queueApi";
import type { IAccount, AzureStorageType, IAzureBlobRequest, IAzureTableRequest } from "../types";

const getAccount = async () => {
  const storageAccount = sessionStorage.getItem('account');

  if (!storageAccount) {
    const activeAccount = await getAccountInfo(msalInstance.getActiveAccount());

    sessionStorage.setItem('account', JSON.stringify(activeAccount));

    return activeAccount;
  }

  return JSON.parse(storageAccount);
}

export const useAccount = () => {
  const [account, setAccount] = useState<IAccount>();

  useEffect(() => {
    getAccount().then(account => setAccount(account));
  }, []);

  return account;
}

export const useAzureGarbageCollector = (clearData: boolean = false) => {
  const tableStorageKey: AzureStorageType = 'table';
  const blobStorageKey: AzureStorageType = 'blob';

  const addRequestData = (storageType: AzureStorageType, data: IAzureTableRequest | IAzureBlobRequest) => {
    const collectionRequestsData = sessionStorage.getItem(storageType);

    if (collectionRequestsData) {
      const parsedData = JSON.parse(collectionRequestsData);

      sessionStorage.setItem(storageType, JSON.stringify([...parsedData, data]));

      return;
    }

    sessionStorage.setItem(storageType, JSON.stringify([data]));
  }

  const deleteTableRequest = (rowKey: string) => {
    const storageData = sessionStorage.getItem(tableStorageKey);

    if (storageData) {
      const parsedData: IAzureTableRequest[] = JSON.parse(storageData);
      const filteredData = parsedData.filter(item => item.rowKey !== rowKey);

      sessionStorage.setItem(tableStorageKey, JSON.stringify(filteredData));
    }
  }

  const deleteBlobRequest = (blobName: string) => {
    const storageData = sessionStorage.getItem(blobStorageKey);

    if (storageData) {
      const parsedData: IAzureBlobRequest[] = JSON.parse(storageData);
      const filteredData = parsedData.filter(item => item.blobName !== blobName);

      sessionStorage.setItem(blobStorageKey, JSON.stringify(filteredData));
    }
  }

  const clearTableStorage = async () => {
    const storageData = sessionStorage.getItem(tableStorageKey);

    if (storageData) {
      const parsedData: IAzureTableRequest[] = JSON.parse(storageData);

      for (const item of parsedData) {
        const { partitionKey, rowKey, sasToken } = item;

        deleteEntityFromTable(partitionKey, rowKey, sasToken)
        await new Promise(resolve => setTimeout(resolve,500));
      }

      sessionStorage.setItem(tableStorageKey, JSON.stringify([]));
    }
  }

  const clearBlobStorage = async () => {
    const storageData = sessionStorage.getItem(blobStorageKey);

    if (storageData) {
      const parsedData: IAzureBlobRequest[] = JSON.parse(storageData);

      for (const item of parsedData) {
        const { containerName, blobName, sasToken } = item;

        deleteBlob(containerName, blobName, sasToken);
        await new Promise(resolve => setTimeout(resolve,500));
      }

      sessionStorage.setItem(blobStorageKey, JSON.stringify([]));
    }
  }

  useEffect(() => {
    if (clearData) {
      clearTableStorage();
      clearBlobStorage();
    }
  }, []);

  return { addRequestData, deleteBlobRequest, deleteTableRequest, clearTableStorage, clearBlobStorage };
}
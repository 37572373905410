import { styled } from "@mui/system";
import { Button } from "@mui/material";

export const StyledButton = styled(Button)<{ marginTop?: string, width?: string }>(({ theme}) => ({
  textTransform: 'none',
  fontSize: 20,
  marginTop: '20px',
  width: '30%',
  color: theme.palette.primary.contrastText,
  '&:hover': {
    background: theme.palette.primary.dark,
  },
  '&:disabled': {
    background: theme.palette.action.disabled,
  },
}));

export const containerStyle = {
  minWidth: 400,
  width: 'max-content',
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};